<script lang="ts" setup>
export type SpinnerColour =
  | 'default'
  | 'primary'
  | 'navy'
  | 'warning'
  | 'danger'
  | 'alpha'
  | 'white'
  | 'grey'
  | 'grey-darker'
  | 'purple';
defineOptions({ name: 'BaseSpinner' });

withDefaults(
  defineProps<{
    size?: 'small' | 'medium' | 'large' | 'fill';
    colour?: SpinnerColour;
  }>(),
  {
    size: 'medium',
    colour: 'default',
  },
);
</script>

<template>
  <div
    class="loader"
    :class="size"
    data-testid="loading-spinner"
    :aria-label="$t('components.spinner.altText')"
  >
    <div class="circle" :class="colour" />
  </div>
</template>

<style scoped>
.loader {
  position: relative;
  display: inline-block;
  color: transparent;
  opacity: 1;
  transition:
    all 0s linear,
    opacity 0.1s ease;
}

.circle {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: theme('colors.neutral.200'); /* TODO replace */
  border-color: transparent currentcolor currentcolor;
  border-style: solid;
  border-width: 3px;
  border-radius: theme('borderRadius.half');
  animation: button-spin 0.6s linear;
  animation-iteration-count: infinite;
}

@keyframes button-spin {
  to {
    transform: rotate(360deg);
  }
}

.small {
  width: 12px;
  height: 12px;
}

.medium {
  width: 16px;
  height: 16px;
}

.large {
  width: 20px;
  height: 20px;
}

.fill {
  width: 100%;
  height: 100%;
}

.default,
.navy {
  color: theme('colors.neutral.500');
}

.primary {
  color: theme('accentColor.primary.DEFAULT');
}

.warning {
  color: theme('accentColor.warning.DEFAULT');
}

.danger {
  color: theme('accentColor.error.DEFAULT');
}

.alpha {
  color: rgb(255 255 255 / 40%);
}

.white {
  color: #fff;
}

.grey {
  color: theme('colors.neutral.200');
}

.grey-darker {
  color: theme('colors.neutral.300');
}

.purple {
  color: theme('colors.purple.600');
}
</style>
